
// VERY IMPORTANT
// This file should never have any imports other than vue. This is used inside core libraries.

import { ref, computed } from "vue";

const mediaQueries: { [key: string]: string } = {
    "isPWA": "only screen and ((display-mode: standalone) or (display-mode: fullscreen))",
    "isMobile": "screen and (pointer: coarse) and (orientation: portrait) and (max-width: 600px), screen and (pointer: coarse) and (orientation: landscape) and (max-height: 600px)",
    "isTablet": "screen and (pointer: coarse) and (orientation: portrait) and (min-width: 600px) and (max-width: 1024px), screen and (pointer: coarse) and (orientation: landscape) and (min-height: 600px) and (max-height: 1024px)",
};

const devTestSites: Array<string> = [
    "https://dev-test.omega365.com",
    "https://dev-nt.omega365.com",
];

function matchQuery(name: string): boolean {
    return window.matchMedia(mediaQueries[name]).matches;
}

export const isPWA = ref<boolean>(matchQuery("isPWA"));
export const isMobile = ref<boolean>(matchQuery("isMobile"));
export const isTablet = ref<boolean>(matchQuery("isTablet"));
export const isFlutter = ref<boolean>(!!window?.__navBarData?.isFlutter);
export const isDevTest = ref<boolean>(devTestSites.includes(window.origin));
export const isMobileOrTablet = computed<boolean>(() => isMobile.value || isTablet.value);
